"use strict";

// Import fancybox bundle
import { Carousel } from "./fancybox_pack/carousel.esm.js";
import "./fancybox_pack/carousel.scss";
import { Thumbs } from "./fancybox_pack/carousel.thumbs.esm.js";
import "./fancybox_pack/carousel.thumbs.scss";
import { Fancybox } from "./fancybox_pack/fancybox.esm.js";
import "./fancybox_pack/fancybox.scss";

// Import css
import "./index.scss";

// Import modules
import lozad from "lozad";
import Swiper from "swiper/bundle";
import Frtabs from "fr-tabs";

// Debug timer
function getCurrentTime() {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  const timeString = `${hours}:${minutes}:${seconds}:${milliseconds}`;
  console.log(timeString);
}

// Lazy load init
const observer = lozad();
observer.observe();


// Helpers / refaction +
const cyrValue = (str) => {
  return /[^а-яёЁ -]/gi.test(str);
};

// Fancybox init
const fancyboxShow = (src, type) => {
  Fancybox.show([
    {
      src: src,
      type: type,
      autoFocus: false,
      trapFocus: false,
      placeFocusBack: false,
    },
  ]);
};

Fancybox.bind("[data-fancybox]", {});

Fancybox.bind('[data-fancybox="gallery"]', {
  idle: false,
  compact: false,
  dragToClose: false,

  animated: false,
  showClass: "f-fadeSlowIn",
  hideClass: false,

  Carousel: {
    infinite: false,
  },

  Images: {
    zoom: false,
    Panzoom: {
      maxScale: 1.5,
    },
  },

  Toolbar: {
    absolute: true,
    display: {
      left: [],
      middle: [],
      right: ["close"],
    },
  },

  Thumbs: {
    type: "classic",
    Carousel: {
      axis: "x",

      slidesPerPage: 1,
      Navigation: true,
      center: true,
      fill: true,
      dragFree: true,

      breakpoints: {
        "(min-width: 640px)": {
          axis: "y",
        },
      },
    },
  },
});

Fancybox.bind('[data-fancybox="gallery-dynamic"]', {
  idle: false,
  compact: false,
  dragToClose: false,

  animated: false,
  showClass: "f-fadeSlowIn",
  hideClass: false,

  Carousel: {
    infinite: false,
  },

  Images: {
    zoom: false,
    Panzoom: {
      maxScale: 1.5,
    },
  },

  Toolbar: {
    absolute: true,
    display: {
      left: [],
      middle: [],
      right: ["close"],
    },
  },

  Thumbs: {
    type: "classic",
    Carousel: {
      axis: "x",

      slidesPerPage: 1,
      Navigation: true,
      center: true,
      fill: true,
      dragFree: true,

      breakpoints: {
        "(min-width: 640px)": {
          axis: "y",
        },
      },
    },
  },
});

// Smooth scrolls
window.addEventListener("DOMContentLoaded", (event) => {
  function smoothScroll(target) {
    target.addEventListener("click", function (e) {
      e.preventDefault();
      const blockID = target.getAttribute("href").substr(1);
      const options = getScrollOptions();
      document.getElementById(blockID).scrollIntoView(options);
    });
  }

  function getScrollOptions() {
    return {
      behavior: "smooth",
      block: "start",
    };
  }

  if (document.querySelector(".smooth-scroll")) {
    const smoothAnchors = document.querySelectorAll(".smooth-scroll");
    smoothAnchors.forEach(smoothScroll);
  }

  if (document.querySelector(".menu-item a")) {
    const menuAnchors = document.querySelectorAll(".menu-item a");
    menuAnchors.forEach(smoothScroll);
  }
});

// Hero swiper
if (document.querySelector(".hero")) {
  var swiperHero = new Swiper(".hero", {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: ".hero__swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".hero__swiper-button-next",
      prevEl: ".hero__swiper-button-prev",
    },
  });
}

// About props swiper
if (document.querySelector(".about__prop__swiper")) {
  var swiperAboutProp = new Swiper(".about__prop__swiper", {
    loop: true,
    breakpoints: {
      900: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: ".about__prop__swiper-button-next",
      prevEl: ".about__prop__swiper-button-prev",
    },
  });
}

// Tab single checker
if (document.querySelector(".dynamic__controls__house__wrapper__tablist")) {
  function checkTablistItem() {
    const tablists = document.querySelectorAll(
      ".dynamic__controls__house__wrapper__tablist"
    );
    tablists.forEach((tablist) => {
      const tablistItem = tablist.querySelector(
        ".dynamic__controls__house__wrapper__tablist__item"
      );
      if (tablistItem && tablistItem.parentElement.children.length === 1) {
        tablistItem.classList.add(
          "dynamic__controls__house__wrapper__tablist__item--bordered"
        );
      }
    });
  }
  checkTablistItem();
}

// Generate slider for construction dynamics
if (
  document.querySelector(
    ".dynamic__controls__house__progress__wrapper__month__item a"
  )
) {
  let activeSliders = [];
  document
    .querySelectorAll(
      ".dynamic__controls__house__progress__wrapper__month__item a"
    )
    .forEach((link) => {
      link.addEventListener("click", function (event) {
        event.preventDefault();
        const ariaControls = this.getAttribute("aria-controls");
        const scriptId = `slider_${ariaControls}`;
        let scriptExists = false;

        if (!activeSliders.includes(scriptId)) {
          scriptExists = true;

          if (document.querySelector(`.slider_${ariaControls}`)) {
            var swiper = new Swiper(`.slider_${ariaControls}`, {
              loop: true,
              slidesPerView: 3,
              paginationClickable: true,
              spaceBetween: 30,
              breakpoints: {
                1920: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1028: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                767: {
                  slidesPerView: 1.6,
                  spaceBetween: 10,
                },
                300: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              },
              navigation: {
                nextEl: `.slider_${ariaControls}-button-next`,
                prevEl: `.slider_${ariaControls}-button-prev`,
              },
            });
          }
        }
      });
    });
}

// Form fecth
const formElements = document.querySelectorAll("form.fetch");

if (formElements) {
  formElements.forEach((form) => {
    form.addEventListener("submit", (evt) => {
      evt.preventDefault();

      let phone = form.querySelector("input[name='phone']").value;
      let data = new FormData(form);

      // assuming leadgets is a valid function that sends data to the server
      leadgets("lead", data, (r) => {
        if (r.status === 1) {
			ym(72482497, "reachGoal", "lead");
          Fancybox.close();

          // assuming submit buttons need to be enabled after successful submission
          let submitButtons = form.querySelectorAll(
            "input[type='submit'], button[type='submit']"
          );
          submitButtons.forEach((button) => button.removeAttribute("disabled"));

          let inputFields = form.querySelectorAll("input, textarea");
          inputFields.forEach((input) => {
            if (
              ["submit", "checkbox", "radio", "hidden"].indexOf(input.type) < 0
            ) {
              input.value = "";
              input.parentNode.classList.remove("valid");
            }
          });

          let checkboxes = form.querySelectorAll("input[type='checkbox']");
          checkboxes.forEach((checkbox) => {
            if (checkbox.name !== "agreement") checkbox.checked = false;
          });

          setTimeout(() => {
            fancyboxShow("#thanks", "inline");
          }, 150);

          setTimeout(() => {
            Fancybox.close();
          }, 5000);
        }
      });
    });
  });
}


// Destroy slider layouts
function destroySlide() {
  if (document.querySelector(".swiper-container__layouts--house1")) {
    swiperHouse1 = new Swiper(".swiper-container__layouts--house1", {
      navigation: {
        nextEl: ".swiper-container__layouts__next--house1",
        prevEl: ".swiper-container__layouts__prev--house1",
      },
      preventClicksPropagation: false,
      preventOnClicks: true,
      simulateTouch: false,
      allowTouchMove: false,
      slidesPerView: 3,
      watchSlidesProgress: true,
      paginationClickable: false,
      spaceBetween: 20,
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        100: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    });
    var swiperHouse1 = document.querySelector(
      ".swiper-container__layouts--house1"
    ).swiper;
    setTimeout(function () {
      swiperHouse1.updateProgress();
      swiperHouse1.updateSize();
      swiperHouse1.updateSlides();
      swiperHouse1.update();
    }, 11);
  }

  if (document.querySelector(".swiper-container__layouts--house2")) {
    swiperHouse2 = new Swiper(".swiper-container__layouts--house2", {
      navigation: {
        nextEl: ".swiper-container__layouts__next--house2",
        prevEl: ".swiper-container__layouts__prev--house2",
      },
      preventClicksPropagation: true,
      preventOnClicks: true,
      simulateTouch: false,
      allowTouchMove: false,
      watchSlidesProgress: true,
      slidesPerView: 3,
      paginationClickable: false,
      spaceBetween: 20,
      noSwipingSelector: "button",
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        100: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    });
    var swiperHouse2 = document.querySelector(
      ".swiper-container__layouts--house2"
    ).swiper;
    setTimeout(function () {
      swiperHouse2.updateSize();
      swiperHouse2.updateSlides();
      swiperHouse2.update();
    }, 500);
  }

  if (document.querySelector(".swiper-container__layouts--house3")) {
    swiperHouse3 = new Swiper(".swiper-container__layouts--house3", {
      navigation: {
        nextEl: ".swiper-container__layouts__next--house3",
        prevEl: ".swiper-container__layouts__prev--house3",
      },
      simulateTouch: false,
      watchSlidesProgress: true,
      allowTouchMove: false,
      slidesPerView: 3,
      paginationClickable: false,
      spaceBetween: 20,
      noSwipingSelector: "button",
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        100: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    });
    var swiperHouse3 = document.querySelector(
      ".swiper-container__layouts--house3"
    ).swiper;
    setTimeout(function () {
      swiperHouse3.updateSize();
      swiperHouse3.updateSlides();
      swiperHouse3.update();
    }, 500);
  }

  if (document.querySelector(".swiper-container__layouts--house4")) {
    swiperHouse4 = new Swiper(".swiper-container__layouts--house4", {
      navigation: {
        nextEl: ".swiper-container__layouts__next--house4",
        prevEl: ".swiper-container__layouts__prev--house4",
      },
      simulateTouch: false,
      allowTouchMove: false,
      watchSlidesProgress: true,
      slidesPerView: 3,
      paginationClickable: false,
      spaceBetween: 20,
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        100: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    });
    var swiperHouse4 = document.querySelector(
      ".swiper-container__layouts--house4"
    ).swiper;
    setTimeout(function () {
      swiperHouse4.updateSize();
      swiperHouse4.updateSlides();
      swiperHouse4.update();
    }, 500);
  }
}

// Refresh slider before changes
const checkboxLabel = document.querySelectorAll(
  ".apartments__layouts__controls__rooms__checkbox__label"
);
checkboxLabel.forEach((label) => {
  label.addEventListener("click", function () {
    const slides = document.querySelectorAll(
      ".swiper-container__layouts__slide"
    );

    destroySlide();
  });
});

// Tab layouts
if (document.querySelector(".dynamic__controls__tabs")) {
  const tabs = document.querySelectorAll(".dynamic__controls__tabs");

  tabs.forEach((tab) => {
    const houses = tab.querySelectorAll(".dynamic__controls__house");

    houses.forEach((house, index) => {
      if (index < houses.length - 1) {
        house.classList.add("visually-hidden", "opacity__hide");
      } else {
        house.classList.add("opacity__visible");
      }
    });
  });
}

// Change year
if (document.querySelector("#year_selector")) {
  const yearSelect = document.getElementById("year_selector");
  yearSelect.addEventListener("change", () => {});

  document
    .querySelector("#year_selector")
    .addEventListener("change", function () {
      document
        .querySelectorAll(".dynamic__controls__house")
        .forEach(function (control) {
          control.classList.add("visually-hidden");
          control.classList.add("opacity__hide");
          control.classList.remove("opacity__visible");
        });
      var selectedYear = document.querySelector("#" + this.value);
      selectedYear.classList.remove("visually-hidden");
      selectedYear.classList.remove("opacity__hide");
      selectedYear.classList.add("opacity__visible");
      const visibleTab = document.querySelector(".opacity__visible");
      if (visibleTab) {
        const tablists = visibleTab.querySelectorAll(
          ".dynamic__controls__house__wrapper__tablist"
        );
        const houseLists = document.querySelectorAll(
          ".dynamic__controls__house"
        );
        houseLists.forEach((houseList) => {
          const firstItem = houseList.querySelector(
            ".dynamic__controls__house__progress"
          );
          if (firstItem) {
            firstItem.setAttribute("aria-hidden", "false");
          }
        });

        const visibleContainer = document.querySelector(".opacity__visible");
        if (visibleContainer) {
          const houses = document.querySelectorAll(".dynamic__controls__house");
          houses.forEach((house) => {
            const progress = house.querySelector(
              ".dynamic__controls__house__progress"
            );
            if (progress) {
              progress.setAttribute("aria-hidden", "false");
            }
          });
        }
      }
    });

  const tablistItems = document.querySelectorAll(
    ".dynamic__controls__house__wrapper__tablist .dynamic__controls__house__wrapper__tablist__item"
  );
  tablistItems.forEach((item) => {
    item.addEventListener("click", () => {
      clickLastTablink();
    });
  });
}

// Add hide for slides
if (document.querySelector(".dynamic__controls__tabs")) {
  let hideContainer = document.querySelectorAll(".dynamic__controls__tabs");
  hideContainer.forEach((container) => {
    let houseElements = container.querySelectorAll(
      ".dynamic__controls__house:not(:last-child)"
    );
    houseElements.forEach((element) => {
      element.classList.add("visually-hidden", "opacity__hide");
    });
  });
}

// Banks swiper
if (document.querySelector(".easypay__banks")) {
  var swiperBanks = new Swiper(".easypay__banks", {
    breakpoints: {
      1328: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
      1200: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 30,
      },
      900: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
      },
      767: {
        loop: true,
        slidesPerView: 2.8,
        spaceBetween: 30,
      },
      699: {
        loop: true,
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      300: {
        loop: true,
        slidesPerView: 1.3,
        spaceBetween: 10,
      },
    },
    slidesPerView: 5,
    spaceBetween: 30,
    navigation: {
      nextEl: ".easypay__banks__swiper-button-next",
      prevEl: ".easypay__banks__swiper-button-prev",
    },
  });
}

// Projects swiper
if (document.querySelector(".projects__realized")) {
  var swiperProjects = new Swiper(".projects__realized", {
    breakpoints: {
      1328: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      767: {
        loop: true,
        slidesPerView: 2.4,
        spaceBetween: 30,
      },
      699: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      300: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    },
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
      nextEl: ".projects__realized__swiper-button-next",
      prevEl: ".projects__realized__swiper-button-prev",
    },
  });
}

// Developer swiper
if (document.querySelector(".developer__info")) {
  var swiperDeveloper = new Swiper(".developer__info", {
    breakpoints: {
      1328: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      699: {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 30,
      },
      300: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    },
    spaceBetween: 30,
    navigation: {
      nextEl: ".developer__info__swiper-button-next",
      prevEl: ".developer__info__swiper-button-prev",
    },
  });
}

// Promo swiper
if (document.querySelector(".promo__swiper")) {
  var swiperPromo = new Swiper(".promo__swiper", {
    pagination: {
      el: ".promo__swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".promo__swiper-button-next",
      prevEl: ".promo__swiper-button-prev",
    },
  });
}

// Solutions swiper
if (document.querySelector(".solutions__swiper")) {
  var swiperSolutions = new Swiper(".solutions__swiper", {
    spaceBetween: 100,
    loop: true,
    effect: "fade",
    pagination: {
      el: ".solutions__swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".solutions__swiper-button-next",
      prevEl: ".solutions__swiper-button-prev",
    },
  });
}

// House switch
if (document.querySelector(".apartments__layouts")) {
  const studioTrigger = document.querySelectorAll(".rooms-trigger-studio");
  const oneTrigger = document.querySelectorAll(".rooms-trigger-one");
  const twoTrigger = document.querySelectorAll(".rooms-trigger-two");
  const twoEuroTrigger = document.querySelectorAll(".rooms-trigger-two-euro");
  const threeEuroTrigger = document.querySelectorAll(
    ".rooms-trigger-three-euro"
  );
  const fourPlusTrigger = document.querySelectorAll(".rooms-trigger-four-plus");
  const threeTrigger = document.querySelectorAll(".rooms-trigger-three");

  if (document.querySelector("#house_tab_1")) {
    document.getElementById("house_tab_1").addEventListener("click", () => {
      studioTrigger.forEach((element) => (element.style.display = "flex"));
      oneTrigger.forEach((element) => (element.style.display = "flex"));
      twoTrigger.forEach((element) => (element.style.display = "flex"));
      twoEuroTrigger.forEach((element) => (element.style.display = "flex"));
      threeEuroTrigger.forEach((element) => (element.style.display = "flex"));
      fourPlusTrigger.forEach((element) => (element.style.display = "flex"));
      threeTrigger.forEach((element) => {
        element.style.display = "none";
      });
    });
  }

  if (document.querySelector("#house_tab_2")) {
    document.getElementById("house_tab_2").addEventListener("click", () => {
      studioTrigger.forEach((element) => (element.style.display = "flex"));
      oneTrigger.forEach((element) => (element.style.display = "flex"));
      twoTrigger.forEach((element) => (element.style.display = "flex"));
      twoEuroTrigger.forEach((element) => (element.style.display = "flex"));
      threeEuroTrigger.forEach((element) => (element.style.display = "flex"));
      fourPlusTrigger.forEach((element) => (element.style.display = "flex"));
      threeTrigger.forEach((element) => (element.style.display = "flex"));
    });
  }
  if (document.querySelector("#house_tab_3")) {
    document.getElementById("house_tab_3").addEventListener("click", () => {
      studioTrigger.forEach((element) => (element.style.display = "flex"));
      oneTrigger.forEach((element) => (element.style.display = "flex"));
      twoTrigger.forEach((element) => (element.style.display = "flex"));
      twoEuroTrigger.forEach((element) => (element.style.display = "flex"));
      threeEuroTrigger.forEach((element) => (element.style.display = "flex"));
      fourPlusTrigger.forEach((element) => (element.style.display = "flex"));
      threeTrigger.forEach((element) => (element.style.display = "flex"));
    });
  }
  if (document.querySelector("#house_tab_4")) {
    document.getElementById("house_tab_4").addEventListener("click", () => {
      studioTrigger.forEach((element) => (element.style.display = "flex"));
      oneTrigger.forEach((element) => (element.style.display = "flex"));
      twoTrigger.forEach((element) => (element.style.display = "flex"));
      twoEuroTrigger.forEach((element) => (element.style.display = "flex"));
      threeEuroTrigger.forEach((element) => (element.style.display = "flex"));
      fourPlusTrigger.forEach((element) => (element.style.display = "flex"));
      threeTrigger.forEach((element) => (element.style.display = "flex"));
    });
  }
}

// Shuffle slides layouts
if (document.querySelector("#rooms_list")) {
  const roomsList = document.querySelectorAll("#rooms_list");
  roomsList.forEach(function (room) {
    const slides = room.querySelectorAll(".swiper-container__layouts__slide");
    const randomOrder = Array.from({ length: slides.length }, (_, i) => i).sort(
      () => Math.random() - 0.5
    );
    randomOrder.forEach(function (index) {
      room.appendChild(slides[index]);
    });

    destroySlide();
  });
}

// Smooth houses scroll
// if (document.querySelector("#layouts")) {
//   function smoothScrollAndClick() {
//     const shadowOne = document.querySelector(
//       ".apartments__scheme__map__shadow--one"
//     );
//     shadowOne.addEventListener("click", function () {
//       document.querySelector("#layouts").scrollIntoView({
//         behavior: "smooth",
//       });
//       setTimeout(function () {
//         document.querySelector("#house_tab_1").click();
//       }, 700);
//     });

//     const shadowTwo = document.querySelector(
//       ".apartments__scheme__map__shadow--two"
//     );
//     shadowTwo.addEventListener("click", function () {
//       document.querySelector("#layouts").scrollIntoView({
//         behavior: "smooth",
//       });
//       setTimeout(function () {
//         document.querySelector("#house_tab_2").click();
//       }, 700);
//     });

//     const shadowThree = document.querySelector(
//       ".apartments__scheme__map__shadow--three"
//     );
//     shadowThree.addEventListener("click", function () {
//       document.querySelector("#layouts").scrollIntoView({
//         behavior: "smooth",
//       });
//       setTimeout(function () {
//         document.querySelector("#house_tab_3").click();
//       }, 700);
//     });

//     const shadowFour = document.querySelector(
//       ".apartments__scheme__map__shadow--four"
//     );
//     shadowFour.addEventListener("click", function () {
//       document.querySelector("#layouts").scrollIntoView({
//         behavior: "smooth",
//       });
//       setTimeout(function () {
//         document.querySelector("#house_tab_4").click();
//       }, 700);
//     });
//   }
//   smoothScrollAndClick();
// }

// Dropdown select year
if (document.querySelector(".select-dropdown__list-item")) {
  document.addEventListener("DOMContentLoaded", createSelect, false);
  function createSelect() {
    var select = document.getElementsByTagName("select"),
      liElement,
      ulElement,
      optionValue,
      iElement,
      optionText,
      selectDropdown,
      elementParentSpan;

    for (var select_i = 0, len = select.length; select_i < len; select_i++) {
      select[select_i].style.display = "none";
      wrapElement(
        document.getElementById(select[select_i].id),
        document.createElement("div"),
        select_i,
        select[select_i].getAttribute("placeholder-text")
      );

      for (var i = 0; i < select[select_i].options.length; i++) {
        liElement = document.createElement("li");
        optionValue = select[select_i].options[i].value;
        optionText = document.createTextNode(select[select_i].options[i].text);
        liElement.className = "select-dropdown__list-item";
        liElement.setAttribute("data-value", optionValue);
        liElement.appendChild(optionText);
        ulElement.appendChild(liElement);

        liElement.addEventListener(
          "click",
          function () {
            displyUl(this);
          },
          false
        );
      }
    }
    function wrapElement(el, wrapper, i, placeholder) {
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);

      document.addEventListener("click", function (e) {
        let clickInside = wrapper.contains(e.target);
        if (!clickInside) {
          let menu = wrapper.getElementsByClassName("select-dropdown__list");
          menu[0].classList.remove("active");
        }
      });

      var buttonElement = document.createElement("button"),
        spanElement = document.createElement("span"),
        spanText = document.createTextNode(placeholder);
      iElement = document.createElement("i");
      ulElement = document.createElement("ul");

      wrapper.className = "select-dropdown select-dropdown--" + i;
      buttonElement.className =
        "select-dropdown__button select-dropdown__button--" + i;
      buttonElement.setAttribute("data-value", "");
      buttonElement.setAttribute("type", "button");
      spanElement.className = "select-dropdown select-dropdown--" + i;
      iElement.className = "zmdi zmdi-chevron-down";
      ulElement.className = "select-dropdown__list select-dropdown__list--" + i;
      ulElement.id = "select-dropdown__list-" + i;

      wrapper.appendChild(buttonElement);
      spanElement.appendChild(spanText);
      buttonElement.appendChild(spanElement);
      buttonElement.appendChild(iElement);
      wrapper.appendChild(ulElement);
    }

    function displyUl(element) {
      if (element.tagName == "BUTTON") {
        selectDropdown = element.parentNode.getElementsByTagName("ul");
        for (var i = 0, len = selectDropdown.length; i < len; i++) {
          selectDropdown[i].classList.toggle("active");
        }
      } else if (element.tagName == "LI") {
        var selectId =
          element.parentNode.parentNode.getElementsByTagName("select")[0];
        selectElement(selectId.id, element.getAttribute("data-value"));
        elementParentSpan =
          element.parentNode.parentNode.getElementsByTagName("span");
        element.parentNode.classList.toggle("active");
        elementParentSpan[0].textContent = element.textContent;
        elementParentSpan[0].parentNode.setAttribute(
          "data-value",
          element.getAttribute("data-value")
        );
      }
    }
    function selectElement(id, valueToSelect) {
      var element = document.getElementById(id);
      element.value = valueToSelect;
      element.setAttribute("selected", "selected");
    }
    var buttonSelect = document.getElementsByClassName(
      "select-dropdown__button"
    );
    for (var i = 0, len = buttonSelect.length; i < len; i++) {
      buttonSelect[i].addEventListener(
        "click",
        function (e) {
          e.preventDefault();
          displyUl(this);
        },
        false
      );
    }
  }

  setTimeout(function () {
    const optionEl = document.querySelectorAll(".select-dropdown__list-item");

    optionEl.forEach((listItemElem) => {
      listItemElem.addEventListener("click", () => {
        const optionElems = document.querySelectorAll(
          ".select-dropdown__list-item"
        );
        optionElems.forEach((optionElem) => {
          if (optionElem.value === listItemElem.dataset.value) {
            optionElem.selected = true;
            const selectElem = document.querySelector("#year_selector");
            selectElem.dispatchEvent(new Event("change"));
          }
        });
      });
    });
  }, 1000);
}

// Phone mask / validate
if (document.querySelector("input[type='tel']")) {
  let phoneInputs = document.querySelectorAll("input[type='tel']");
  let getInputNumbersValue = function (input) {
    return input.value.replace(/\D/g, "");
  };
  let onPhonePaste = function (e) {
    let input = e.target,
      inputNumbersValue = getInputNumbersValue(input);
    let pasted = e.clipboardData || window.clipboardData;
    if (pasted) {
      let pastedText = pasted.getData("Text");
      if (/\D/g.test(pastedText)) {
        input.value = inputNumbersValue;
        return;
      }
    }
  };
  let onPhoneInput = function (e) {
    let input = e.target,
      inputNumbersValue = getInputNumbersValue(input),
      selectionStart = input.selectionStart,
      formattedInputValue = "";
    if (!inputNumbersValue) {
      return (input.value = "");
    }
    if (input.value.length != selectionStart) {
      if (e.data && /\D/g.test(e.data)) {
        input.value = inputNumbersValue;
      }
      return;
    }
    if (
      ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].indexOf(
        inputNumbersValue[0]
      ) > -1
    ) {
      if (inputNumbersValue[0] == "9")
        inputNumbersValue = "7" + inputNumbersValue;
      let firstSymbols = inputNumbersValue[0] == "8" ? "8" : "+7";
      formattedInputValue = input.value = firstSymbols + " ";
      if (inputNumbersValue.length > 1) {
        formattedInputValue += "(" + inputNumbersValue.substring(1, 4);
        this.classList.add("is-invalid");
        document
          .querySelectorAll("#submit-btn")
          .forEach((item) => item.classList.add("disabled"));
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += ") " + inputNumbersValue.substring(4, 7);
      }
      if (inputNumbersValue.length >= 8) {
        formattedInputValue += "-" + inputNumbersValue.substring(7, 9);
      }
      if (inputNumbersValue.length >= 10) {
        formattedInputValue += "-" + inputNumbersValue.substring(9, 11);
      }
      if (inputNumbersValue.length >= 11) {
        this.classList.remove("is-invalid");
        this.classList.add("is-valid");
        document
          .querySelectorAll("#submit-btn")
          .forEach((item) => item.classList.remove("disabled"));
      }
    } else {
      formattedInputValue = "+" + inputNumbersValue.substring(0, 16);
    }
    input.value = formattedInputValue;
  };
  let onPhoneKeyDown = function (e) {
    let inputValue = e.target.value.replace(/\D/g, "");
    if (e.keyCode == 8 && inputValue.length == 1) {
      e.target.value = "";
    }
  };

  document.querySelectorAll("#submit-btn").forEach(function (btn) {
    for (let phoneInput of phoneInputs) {
      phoneInput.addEventListener("keydown", onPhoneKeyDown);
      phoneInput.addEventListener("input", onPhoneInput, false);
      phoneInput.addEventListener("paste", onPhonePaste, false);
    }
  });
}

// Input name capitalize / validate
if (document.querySelector("input[name='name']")) {
  function capitalize(input) {
    input.value = input.value.replace(/( |^)[а-яёa-z]/g, function (u) {
      return u.toUpperCase();
    });
  }

  let nameInputs = document.querySelectorAll("input[name='name']");
  nameInputs.forEach((nameInput) => {
    nameInput.addEventListener("input", function () {
      capitalize(this);
    });

    // Regex to check for numbers
    let regnumberform = /[0-9]/g;
    nameInput.oninput = function () {
      if (this.value.match(regnumberform)) {
        this.value = this.value.replace(regnumberform, "");
      } else {
        this.classList.add("is-valid");
      }
    };
  });
}

// Popup title replace
document.querySelectorAll('[href="#popup"]').forEach((link) => {
  link.addEventListener("click", (event) => {
    const title = link.querySelector("span").innerText;
    const modalTitle = document.querySelector(".modal__title__js");
    const modalTitleValue = document.querySelector("#modal_title_value");
    modalTitle.innerText = title;

    let promo = link.dataset.promo;

    const modalPromoValue = document.querySelector("#modal_promo");
    modalPromoValue.value = promo ? promo : "";

    modalTitleValue.value = "Форма заявки - модальное окно: " + title;
  });
});

document.querySelectorAll('[href="#popup__mortgage"]').forEach((link) => {
  link.addEventListener("click", (event) => {
    const house = link.getAttribute("data-house");
    const plan = link.getAttribute("data-plan");
    const square = link.getAttribute("data-square");
    console.log(square);
    const layout = link.getAttribute("data-layout");
    const categoryRoom = link.getAttribute("data-category-room");
    const title = link.querySelector("span").innerText;
    const modalTitle1 = document.querySelector(".modal__title__js");
    const modalTitle2 = document.querySelector(".modal__title__mortgage__js");
    const modalHouse = document.querySelector("#popup_mortgage_house");
    const modalRooms = document.querySelector("#popup_mortgage_rooms");
    const modalSquare = document.querySelector("#popup_mortgage_square");
    const modalType = document.querySelector("#popup_mortgage_type");

    if (modalTitle1) {
      modalTitle1.innerText = title;
    }

    if (modalTitle2) {
      modalTitle2.textContent = categoryRoom;
    }

    if (modalHouse) {
      modalHouse.value = house;
    }

    if (modalRooms) {
      modalRooms.value = plan;
    }

    if (modalSquare) {
      modalSquare.value = square;
    }

    if (modalType) {
      modalType.value = layout;
    }
  });
});

// Title replace rooms
if (document.querySelector(".button__common--layoutsmortgage")) {
  const buttons = document.querySelectorAll(
    ".button__common--layoutsmortgage[data-category-room]"
  );

  buttons.forEach((button) => {
    const categoryRoom = button.dataset.categoryRoom;
    switch (categoryRoom) {
      case "Студия":
        button.dataset.categoryRoom = "квартиру студию";
        break;
      case "Однокомнатная":
        button.dataset.categoryRoom = "однокомнатную квартиру";
        break;
      case "Двухкомнатная":
        button.dataset.categoryRoom = "двухкомнатную квартиру";
        break;
      case "Трехкомнатная":
        button.dataset.categoryRoom = "трёхкомнатную квартиру";
        break;
      case "Евродвушка":
        button.dataset.categoryRoom =
          "двухкомнатную квартиру с евро планировкой";
        break;
      case "Евротрёшка":
        button.dataset.categoryRoom =
          "трёхкомнатную квартиру с евро планировкой";
        break;
      case "Четырехкомнатная":
        button.dataset.categoryRoom = "четырехкомнатную квартиру";
        break;
    }
  });
}

// E-mail mask / validate
if (document.querySelector("input[type='email']")) {
  const EMAIL_REGEXP =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
  const inputs = document.querySelectorAll("input[type='email']");

  function isEmailValid(value) {
    return EMAIL_REGEXP.test(value);
  }

  function onInput() {
    if (isEmailValid(this.value)) {
      this.classList.remove("is-invalid");
      this.classList.add("is-valid");
      document.querySelectorAll("#submit-btn").forEach(function (button) {
        button.classList.remove("disabled--email");
      });
    } else if (this.value.length === 0) {
      this.classList.remove("is-invalid", "is-valid");
      document.querySelectorAll("#submit-btn").forEach(function (button) {
        button.classList.remove("disabled--email");
      });
    } else {
      this.classList.add("is-invalid");
      document.querySelectorAll("#submit-btn").forEach(function (button) {
        button.classList.add("disabled--email");
      });
    }
  }

  inputs.forEach(function (input) {
    input.addEventListener("input", onInput);
  });
}

// Formated first pay
if (document.querySelector(".modal__content__input")) {
  const firstPayInputs = document.querySelectorAll(
    ".modal__content__input[name=first_pay]"
  );
  function formatInputValue(value) {
    return value
      .replace(/[^\d\s]/g, "")
      .replace(/\s/g, "")
      .replace(/(\d{1,3})(?=(\d{3})+$)/g, "$1 ");
  }
  function restrictInput(event) {
    const value = parseInt(
      formatInputValue(event.target.value).replace(/\s/g, "")
    );
    event.target.value = formatInputValue(event.target.value);
    if (value < 600000) {
      document
        .querySelector(".modal__content__notification")
        .classList.add("modal__content__notification--alert");
    } else {
      document
        .querySelector(".modal__content__notification")
        .classList.remove("modal__content__notification--alert");
    }
  }
  firstPayInputs.forEach((input) => {
    input.addEventListener("input", restrictInput);
  });
}

// Last month logic
function clickLastTablink() {
  const monthWrappers = document.querySelectorAll(
    ".dynamic__controls__house__progress__wrapper__month"
  );
  monthWrappers.forEach((monthWrapper) => {
    const lastTabItem = monthWrapper.querySelector(
      ".dynamic__controls__house__progress__wrapper__month__item:last-child a"
    );
    if (lastTabItem) {
      lastTabItem.click();
    }
  });
}

// Click select to tab 1 - 2020
setTimeout(function () {
  document.addEventListener("click", function (event) {
    if (
      event.target.classList.contains("select-dropdown__list-item") &&
      event.target.dataset.value === "year_2020"
    ) {
      document.getElementById("dynamic_house_1_2020").click();
    }
  });
}, 1000);

// Click select to tab 1 - 2021
setTimeout(function () {
  document.addEventListener("click", function (event) {
    if (
      event.target.classList.contains("select-dropdown__list-item") &&
      event.target.dataset.value === "year_2021"
    ) {
      document.getElementById("dynamic_house_1_2021").click();
    }
  });
}, 1000);

// Click select to tab 1 - 2022
setTimeout(function () {
  document.addEventListener("click", function (event) {
    if (
      event.target.classList.contains("select-dropdown__list-item") &&
      event.target.dataset.value === "year_2022"
    ) {
      document.getElementById("dynamic_house_1_2022").click();
    }
  });
}, 1000);

// Click select to tab 1 - 2023
setTimeout(function () {
  document.addEventListener("click", function (event) {
    if (
      event.target.classList.contains("select-dropdown__list-item") &&
      event.target.dataset.value === "year_2023"
    ) {
      document.getElementById("dynamic_house_1_2023").click();
    }
  });
}, 1000);

function scrollToDynamic() {
  const element = document.getElementById("dynamic_house_2_2023");
  const rect = element.getBoundingClientRect();
  if (rect.top <= window.innerHeight && rect.bottom >= 0) {
    element.click();
    document.getElementById("header").scrollIntoView();
    clickLastTablink();
    window.removeEventListener("scroll", scrollToDynamic);
  }
}
window.addEventListener("scroll", scrollToDynamic);

// Advanced layout swiper
setTimeout(function () {
  const sliders = document.querySelectorAll("[data-swiper-advanced]");

  sliders.forEach((slider) => {
    const sliderCount = slider.getAttribute("data-swiper-advanced");
    const sliderElement = document.getElementById(
      `advanced__slider-${sliderCount}`
    );
    const slides = sliderElement.querySelectorAll(
      `.advanced__slider__slide-${sliderCount}`
    );
    const totalSlides = slides.length;
    const currentSlide = document.querySelector(
      `.current-slide-${sliderCount}`
    );
    const totalSlidesText = document.querySelector(
      `.total-slides-${sliderCount}`
    );
    let activeSlideIndex = 0;

    const showSlide = (index) => {
      slides.forEach((slide) => slide.classList.remove("active"));
      slides[activeSlideIndex].classList.add("active");
      currentSlide.textContent = activeSlideIndex + 1;
      const fractions = document.querySelectorAll(`.fraction-${sliderCount}`);
      fractions.forEach((fraction) => fraction.classList.remove(`active`));
      fractions[activeSlideIndex].classList.add(`active`);
    };

    const prevBtn = document.querySelector(`.prev-${sliderCount}`);
    prevBtn.addEventListener("click", () => {
      if (activeSlideIndex === 0) {
        activeSlideIndex = totalSlides - 1;
      } else {
        activeSlideIndex--;
      }
      showSlide(activeSlideIndex);
    });

    const nextBtn = document.querySelector(`.next-${sliderCount}`);
    nextBtn.addEventListener("click", () => {
      if (activeSlideIndex === totalSlides - 1) {
        activeSlideIndex = 0;
      } else {
        activeSlideIndex++;
      }
      showSlide(activeSlideIndex);
    });

    showSlide(activeSlideIndex);
    totalSlidesText.textContent = totalSlides;
  });
}, 3000);

// Sticky header
function minifyHeader() {
  const header = document.getElementById("header");
  const about = document.getElementById("about");
  const main = document.querySelector("main");
  const scrollTop = window.pageYOffset;
  if (scrollTop > about.offsetTop) {
    main.classList.add("collapsed");
    header.classList.add("minified");
  }
}

window.addEventListener("scroll", minifyHeader);

// Side bar menu disabled body
const sidebar = document.getElementById("sidebar");
const toggleButton = document.getElementById("sidebar-toggle");
const mainMask = document.querySelector("body");

toggleButton.addEventListener("click", () => {
  sidebar.classList.toggle("show");
  mainMask.classList.toggle("disabled--scroll");
});

// Burger btn anim
window.addEventListener("DOMContentLoaded", function () {
  const hamburger = document.getElementById("hamburger");
  const jaw = document.querySelector(".jaw");

  hamburger.addEventListener("click", function () {
    if (hamburger.classList.contains("is-open")) {
      hamburger.classList.remove("is-open");
      hamburger.classList.add("is-close");
      hamburger.classList.add("is-closed");
      jaw.classList.add("show");
    } else {
      hamburger.classList.remove("is-close");
      hamburger.classList.add("is-open");
      hamburger.classList.remove("is-closed");
      jaw.classList.remove("show");
    }
  });
});

// Side bar close on click
window.addEventListener("DOMContentLoaded", function () {
  const hamburger = document.getElementById("hamburger");
  const menuItems = document.querySelectorAll(".menu-item");

  menuItems.forEach(function (menuItem) {
    menuItem.addEventListener("click", function () {
      hamburger.click();
    });
  });
});

// Formated href:tel
const hrefTel = document.querySelectorAll('a[href^="tel:"]');

hrefTel.forEach((link) => {
  link.setAttribute("href", link.getAttribute("href").replace(/\s+/g, ""));
});

// Scroll jaw (contacts)
setTimeout(function () {
  document.querySelector(".jaw").classList.add("show");
}, 2000);

// Empty layout checkup
const checkboxes = document.querySelectorAll(
  ".apartments__layouts__controls__rooms__checkbox__label"
);

function toggleMarkedClass() {
  this.classList.toggle("marked");
}

function checkAndClickStudio() {
  let hasMarked = false;

  checkboxes.forEach((checkbox) => {
    if (checkbox.classList.contains("marked")) {
      hasMarked = true;
    }
  });

  if (!hasMarked) {
    const studioCheckbox = document.querySelector('label[for="studio"]');
    studioCheckbox.click();
  }
}

checkboxes.forEach((label) => {
  label.addEventListener("click", () => {
    toggleMarkedClass.call(label);
    checkAndClickStudio();
    destroySlide();
  });
});

// swiper plug
const swiperContainer = document.querySelector(".swiper");
const anchors = swiperContainer.querySelectorAll("a");

anchors.forEach((anchor) => {
  anchor.removeEventListener("mousedown", preventDefaultAndStopPropagation);
  anchor.addEventListener("mousedown", preventDefaultAndStopPropagation);
});

function preventDefaultAndStopPropagation(event) {
  event.preventDefault();
  event.stopImmediatePropagation();
}
